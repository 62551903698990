<template>
  <div class="all-height d-flex justify-center">
    <div class="all-width">
      <v-card class="" loader-height="2" :loading="loading" :disabled="loading">
        <v-card-text>
          <div class="d-flex align-center mb-2">
            <v-chip small label color="bntColorwhite--text" class="" v-if="metadata.stage === 0">Open</v-chip>
            <v-chip small label color="error" class="" v-else-if="metadata.stage === 1">Freezed</v-chip>
            <v-chip small label color="success" class="" v-else-if="metadata.stage === 2">Closed</v-chip>
            <v-btn icon small class="ml-2" @click="refreshData()"><v-icon>mdi-refresh</v-icon></v-btn>
          </div>
          <div class="d-flex align-center mb-4" v-if="metadata._id">
             <div class="d-flex align-center mb-2" v-if="metadata._id">
             <div>
               <div class="d-flex align-center">
                 <div class="subtitle-1 font-weight-bold mr-2">
                   {{ metadata.description }}
                 </div>
               </div>
               <div>
                 <span class="mr-2">
                   <v-icon class="mr-1">mdi-calendar-range</v-icon> Period:
                 </span>
                 <span class="mr-1">{{ $nova.formatDate(metadata.from) }}</span>
                 <span class="mr-1">- {{ $nova.formatDate(metadata.to) }}</span>
               </div>
             </div>
             <v-spacer></v-spacer>
           </div>
            <v-spacer></v-spacer>
           
            <div class="d-flex align-center">
              <v-spacer></v-spacer>
              <div class="d-flex align-center mb-2 primary--text mr-4">
                <span class="display-1">{{summary.count || 0}}</span>
                <span class="ml-1 line-1-2">Total<br />GLs</span>
              </div>
              <div class="d-flex align-center mb-2 success--text mr-4">
                <span class="display-1">{{summary.approved || 0}}</span>
                <span class="ml-1 line-1-2">Approved<br />GLs</span>
              </div>
              <div class="d-flex align-center mb-2 info--text mr-4">
                <span class="display-1">{{summary.pending || 0}}</span>
                <span class="ml-1 line-1-2">Pending Submission<br />GLs</span>
              </div>
              <div class="d-flex align-center mb-2 warning--text mr-4">
                <span class="display-1">{{summary.pendingapproval || 0}}</span>
                <span class="ml-1 line-1-2">Pending Approval<br />GLs</span>
              </div>
              <div class="d-flex align-center mb-2 error--text mr-4">
                <span class="display-1">{{summary.rejected || 0}}</span>
                <span class="ml-1 line-1-2">Rejected<br />GLs</span>
              </div>
              <div class="d-flex align-center mb-2 grey--text">
                <span class="display-1">{{summary.immaterial || 0}}</span>
                <span class="ml-1 line-1-2">Immaterial<br />GLs</span>
              </div>
              <v-spacer></v-spacer>
            </div>
          </div>
          <div class="d-flex align-center mb-2" v-if="metadata._id && data.length > 0">
            <BulkUploadData v-if="(!embed && metadata.stage === 0 ) && ($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add') || $nova.hasRight(right, 'delete'))" @reload="refreshData()" :project="(metadata || {})._id" :config="setting" />
            <!-- <div class="mr-2">
              <v-chip label small color="info">Submitter</v-chip>
            </div> -->
            <!-- <v-chip small label class="pr-0 mr-2" outlined>Currency<v-chip class="ml-2" small label>USD
                000</v-chip></v-chip> -->
            <v-menu offset-y transition="slide-y-transition" :close-on-content-click="false" dense>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small class="shadow-off" v-bind="attrs" v-on="on">Filter<v-icon
                    class="ml-1">mdi-filter-menu</v-icon></v-btn>
              </template>
              <v-list dense>
                <v-list-item class="ma-0 pa-0">
                  <v-list-item-title class="ma-0 px-2 pb-1">
                    <v-checkbox @click="generateFilteredData()" color="" dense hide-details class="mr-4"
                      label="Ignore Nil Values" v-model="ignore0values"></v-checkbox>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="ma-0 pa-0">
                  <v-list-item-title class="ma-0 px-2 pb-1">
                    <v-checkbox @click="generateFilteredData()" color="info" dense hide-details class="mr-4"
                      label="Commentary pending" v-model="selectallpending"></v-checkbox>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="ma-0 pa-0">
                  <v-list-item-title class="ma-0 px-2 pb-1">
                    <v-checkbox @click="generateFilteredData()" color="warning" dense hide-details class="mr-4"
                      label="Commentary pending approval" v-model="selectallpendingapproval"></v-checkbox>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="ma-0 pa-0">
                  <v-list-item-title class="ma-0 px-2 pb-1">
                    <v-checkbox @click="generateFilteredData()" color="success" dense hide-details class="mr-4"
                      label="Approved" v-model="selectallapproved"></v-checkbox>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="ma-0 pa-0">
                  <v-list-item-title class="ma-0 px-2 pb-1">
                    <v-checkbox @click="generateFilteredData()" color="error" dense hide-details class="mr-4"
                      label="Rejected" v-model="selectallrejected"></v-checkbox>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="ma-0 pa-0">
                  <v-list-item-title class="ma-0 px-2 pb-1">
                    <v-checkbox @click="generateFilteredData()" color="" dense hide-details class="mr-4"
                      label="Immaterial" v-model="selectallimmaterial"></v-checkbox>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <!-- <v-menu offset-y transition="slide-y-transition" :close-on-content-click="false" dense>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small class="shadow-off" v-bind="attrs" v-on="on">Filter<v-icon
                    class="ml-1">mdi-filter-menu</v-icon></v-btn>
              </template>
              <v-list dense class="">
                <v-list-item>
                  <v-list-item-title>
                    <v-checkbox color="" dense hide-details class="mr-4" label="Ignore Nil Values"></v-checkbox>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <v-checkbox color="info" dense hide-details class="mr-4" label="Commentary awaited"></v-checkbox>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <v-checkbox color="warning" dense hide-details class="mr-4"
                      label="Commentary awaiting approval"></v-checkbox>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <v-checkbox color="success" dense hide-details class="mr-4" label="Approved"></v-checkbox>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <v-checkbox color="error" dense hide-details class="mr-4" label="Rejected"></v-checkbox>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <v-checkbox color="" dense hide-details class="mr-4" label="Immaterial"></v-checkbox>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->
            <v-switch v-if="customfilter.length > 0" inset class="ml-4 mr-0 mt-0" v-model="customfilterstoggle"
              label="Custom View" hide-details></v-switch>
            <v-spacer></v-spacer>
            <div class="d-flex align-center ml-2">
              <v-tooltip left content-class="tooltip-left">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" small icon class="" target="_blank" @click="downloadExcel()"><v-icon>mdi-file-excel-outline</v-icon></v-btn>
                </template>
                <span>Download Excel</span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-1" @click="downloadPPT()" v-bind="attrs" v-on="on" icon small>
                    <v-icon class="">mdi-presentation</v-icon>
                  </v-btn>
                </template>
                <span>Download PPT</span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom" v-if="multiselectdata.length > 0 && metadata.stage === 0 && (metadata.managerlist.indexOf(userid) > -1)">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" color="info" icon small class=""
                    @click="bulkActionUser('preparer')"><v-icon>mdi-account</v-icon></v-btn>
                </template>
                <span>Bulk Add/Remove Preparer</span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom" v-if="multiselectdata.length > 0 && metadata.stage === 0 && (metadata.managerlist.indexOf(userid) > -1)">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" color="warning" icon small class=""
                    @click="bulkActionUser('approver')"><v-icon>mdi-account</v-icon></v-btn>
                </template>
                <span>Bulk Add/Remove Approver</span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom" v-if="multiselectdata.length > 0 && metadata.stage === 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon small class=""
                    @click="bulksubmit = true; bulkcommantorydialog = true; selectedcommentary = {};commantorydialogtype='add'"><v-icon>mdi-plus-circle</v-icon></v-btn>
                </template>
                <span>Add Commentary to Selected</span>
              </v-tooltip>
              <!-- <v-tooltip bottom content-class="tooltip-bottom">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" small icon class=""
                    color="warning"><v-icon>mdi-email</v-icon></v-btn>
                </template>
                <span>Send Reminder mail to approver</span>
              </v-tooltip> -->
              <v-tooltip bottom content-class="tooltip-bottom" v-if="metadata.stage === 0 && (metadata.managerlist.indexOf(userid) > -1)">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" small icon @click="changeProjectStatus('freeze')"
                    class=""><v-icon>mdi-circle-off-outline</v-icon></v-btn>
                </template>
                <span>Freeze</span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom" v-if="metadata.stage === 1 && (metadata.managerlist.indexOf(userid) > -1)">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" small icon @click="changeProjectStatus('unfreeze')"
                    class=""><v-icon>mdi-checkbox-blank-circle-outline</v-icon></v-btn>
                </template>
                <span>Unfreeze</span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom" v-if="(metadata.stage === 0 || metadata.stage === 1) && (metadata.managerlist.indexOf(userid) > -1)">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" small icon @click="changeProjectStatus('close')"
                    class=""><v-icon>mdi-circle</v-icon></v-btn>
                </template>
                <span>Close</span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom" v-if="(metadata.stage === 2) && (metadata.managerlist.indexOf(userid) > -1)">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" small icon @click="changeProjectStatus('open')"
                    class=""><v-icon>mdi-circle-outline</v-icon></v-btn>
                </template>
                <span>Open</span>
              </v-tooltip>
              <!-- <v-tooltip left content-class="tooltip-left">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" small icon class=""
                    target="_blank"><v-icon>mdi-file-excel-outline</v-icon></v-btn>
                </template>
                <span>Download Excel</span>
              </v-tooltip> -->
              <!-- <v-tooltip left content-class="tooltip-left">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" small icon class=""
                    target="_blank"><v-icon>mdi-presentation</v-icon></v-btn>
                </template>
                <span>Download PPT</span>
              </v-tooltip> -->
              <lb-conversation
                heading="Conversation"
                v-if="($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))"
                width="600"
                :recurring="false"
                :recurringText="''"
                :closed="metadata.stage !== 0"
                @markread="metadata.conversation_read = true"
                :notify="!metadata.conversation_read"
                :getapi="'/v2/fluxmanagement/project/getconversation/'+metadata._id"
                :sendapi="'/v2/fluxmanagement/project/addconversation/'+metadata._id"
                :readapi="'/v2/fluxmanagement/project/markreadconversation/'+metadata._id"
              />
              <!-- <v-tooltip left content-class="tooltip-left">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="" v-bind="attrs" v-on="on" icon small>
                    <v-badge color="error" offset-x="10" offset-y="10" dot>
                      <v-icon class="">mdi-comment-text-multiple</v-icon>
                    </v-badge>
                  </v-btn>
                </template>
                <span>Conversations</span>
              </v-tooltip> -->
              <!-- <v-tooltip left content-class="tooltip-left">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" small icon class=""><v-icon>mdi-table-large-plus</v-icon></v-btn>
                </template>
                <span>Upload Bulk Commentary File</span>
              </v-tooltip> -->
            </div>
            <div class="line-1 ml-4">
              <div class="d-flex align-center">
                <v-avatar size="6" color="warning"></v-avatar>
                <span class="ml-2">Approver</span>
              </div>
              <div class="d-flex align-center">
                <v-avatar size="6" color="info"></v-avatar>
                <span class="ml-2">Preparer</span>
              </div>
            </div>
          </div>
          <div v-if="metadata._id && filtereddata.length > 0">
            <v-simple-table dense class="FC-Table">
              <template>
                <thead>
                  <tr>
                    <th scope="checkbox" class="text-center" rowspan="2" v-if="metadata.stage === 0 && ($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add') || $nova.hasRight(right, 'delete')) ">
                      <!-- <v-checkbox hide-details class="ma-0 pa-0" dense></v-checkbox> -->
                    </th>
                    <th scope="glcode" class="text-center" rowspan="2">
                      <span> GL Code </span> <br />
                      <v-btn small class="shadow-off" icon @click="customfiltersdialog = true">
                        <v-icon>mdi-filter-menu</v-icon></v-btn>
                    </th>
                    <th scope="name" rowspan="2">Name</th>
                    <th scope="amount1" rowspan="2" class="text-right primary--text" :style="`background-color: ${headingcolors[0] || ''
                      } !important`">
                      {{ setting.amount1name || "Amount 1" }}
                    </th>
                    <th scope="type" colspan="4" class="text-center primary--text" v-for="v in setting.comparative" :key="v" :style="`background-color: ${headingcolors[v] || ''
                      } !important`">
                      <span v-if="v === 1">{{ setting.amount1name }} VS
                        {{ setting.amount2name }}</span>
                      <span v-else-if="v === 2">{{ setting.amount1name }} VS
                        {{ setting.amount3name }}</span>
                      <span v-else-if="v === 3">{{ setting.amount1name }} VS
                        {{ setting.amount4name }}</span>
                      <span v-else-if="v === 4">{{ setting.amount1name }} VS
                        {{ setting.amount5name }}</span>
                    </th>
                    <th scope="action" class="text-center" rowspan="2">
                      Action
                    </th>
                    <th scope="status" class="text-center" rowspan="2">
                      Status<br />
                      <v-btn icon small @click="statushelpdialog = true"><v-icon>mdi-help-circle</v-icon></v-btn>
                    </th>
                  </tr>
                  <tr>
                    <template v-for="v in setting.comparative">
                      <th v-if="v === 1" :key="v + 'a'" :scope="'amount' + v + 1" class="text-right primary--text" :style="`background-color: ${headingcolors[v] || ''
                        } !important`">
                        {{ setting.amount2name }}
                      </th>
                      <th v-if="v === 2" :key="v + 'a'" :scope="'amount' + v + 1" class="text-right primary--text" :style="`background-color: ${headingcolors[v] || ''
                        } !important`">
                        {{ setting.amount3name }}
                      </th>
                      <th v-if="v === 3" :key="v + 'a'" :scope="'amount' + v + 1" class="text-right primary--text" :style="`background-color: ${headingcolors[v] || ''
                        } !important`">
                        {{ setting.amount4name }}
                      </th>
                      <th v-if="v === 4" :key="v + 'a'" :scope="'amount' + v + 1" class="text-right primary--text" :style="`background-color: ${headingcolors[v] || ''
                        } !important`">
                        {{ setting.amount5name }}
                      </th>
                      <th :key="v + 'b'" :scope="'varamount' + v" class="text-right primary--text" :style="`background-color: ${headingcolors[v] || ''
                        } !important`">
                        Variance Amt
                      </th>
                      <th :key="v + 'c'" :scope="'varpercent' + v" class="text-right primary--text" :style="`background-color: ${headingcolors[v] || ''
                        } !important`">
                        Variance %
                      </th>
                      <th :key="v + 'd'" :scope="'commentary' + v" class="text-center primary--text" :style="`background-color: ${headingcolors[v] || ''
                        } !important`">
                        Commentary
                      </th>
                    </template>
                  </tr>
                </thead>
                <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                  <tr v-for="(v, index) in filtereddata" :key="index">
                    <template v-if="v.type === 'group' || collapsed.indexOf(v.__category) === -1">
                      <td :style="(v.type === 'group') ? 'background-color: rgba(0,0,100,0.05)' : ''" v-if="metadata.stage === 0 && ($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add') || $nova.hasRight(right, 'delete'))">
                        <v-checkbox v-if="v.type !== 'group' && [-1,0,1].indexOf(v.stage) > -1" hide-details v-model="multiselectdata" :value="v._id"
                          multiple class="ma-0 pa-0" dense></v-checkbox>
                      </td>
                      <template v-if="v.type === 'group'">
                        <td colspan="2" class="cursor-pointer" style="background-color: rgba(0,0,100,0.05)"
                          @click="toggleExpand(v.__category)">
                          <div class="d-flex pr-2 align-center">
                            <div class="d-flex flex-column line-1 mr-2 justify-center">
                              <v-avatar v-if="v.isapprover" size="6" class="mb-1" color="warning"></v-avatar>
                              <v-avatar v-if="v.ispreparer" size="6" color="info"></v-avatar>
                              <v-avatar v-if="!v.isapprover && !v.ispreparer" size="6" color="transperant"></v-avatar>
                            </div>
                            <div class="font-weight-bold">
                              <span>{{ v.name }}</span>
                              <v-icon class="ml-2" v-if="collapsed.indexOf(v.__category) > -1">mdi-chevron-right</v-icon>
                              <v-icon class="ml-2" v-else>mdi-chevron-down</v-icon>
                            </div>
                          </div>
                        </td>
                      </template>
                      <template v-if="v.type === 'group'">
                        <td :colspan="3 + ((setting.comparative || 1) * 4)" class="cursor-pointer"
                          style="background-color: rgba(0,0,100,0.05)" @click="toggleExpand(v.__category)">
                          <div class="d-flex pr-2 align-center">
                            <v-spacer></v-spacer>
                            <!-- <v-chip x-small color="primary" class="mr-1 px-2" text>{{v.total}}</v-chip>
                            <v-chip x-small color="success" class="mr-1 px-2" text>{{v.complete}}</v-chip>
                            <v-chip x-small color="info" class="mr-1 px-2" text>{{v.pending}}</v-chip>
                            <v-chip x-small color="warning" class="mr-1 px-2" text>{{v.approve}}</v-chip>
                            <v-chip x-small color="grey" dark class="mr-1 px-2" text>{{v.others}}</v-chip> -->
                          </div>
                        </td>
                      </template>
                      <template v-else-if="collapsed.indexOf(v.__category) === -1">
                        <td>
                          <div class="d-flex align-center">
                            <v-avatar v-if="(v.approver || []).indexOf(userid) > -1" size="6" color="warning"></v-avatar>
                            <v-avatar v-else-if="(v.preparer || []).indexOf(userid) > -1" size="6"
                              color="info"></v-avatar>
                            <v-avatar v-else size="6" color="transperant"></v-avatar>
                            <span class="ml-2">{{ v.glcode }}</span>
                          </div>
                        </td>
                        <td>
                          <!-- <v-tooltip bottom content-class="tooltip-bottom">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="error" v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>
                            </template>
                            <span>Add Commentary </span>
                          </v-tooltip> -->
                          {{ v.name }}
                        </td>
                        <td :style="`background-color: ${bodycolors[0] || ''} !important`" class="text-right">
                          {{ $nova.formatCurrency(v.amount1, "", false, true, 1) }}
                        </td>
                        <template v-for="i in setting.comparative">
                          <td class="text-right" :style="`background-color: ${bodycolors[i] || ''} !important`" :key="i + 'a'">
                            {{
                              $nova.formatCurrency(v["amount" + (i + 1)],"",false,true,1) 
                            }} 
                            <div class="d-flex align-center">
                              <div class="d-flex flex-column">
                                <v-tooltip left content-class="tooltip-left" v-if="v.variance1flag">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on"
                                      style="font-size:7px !important; margin: 1px 1px !important" class="error--text"
                                      id="l_c_m_c_pro">mdi-circle</v-icon>
                                  </template>
                                  <span>Local Control Materiality crossed</span>
                                </v-tooltip>
                                <v-spacer></v-spacer>
                                <v-tooltip left content-class="tooltip-left" v-if="v.variance2flag">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on"
                                      style="font-size:7px !important; margin: 1px 1px !important" class="blue--text"
                                      id="l_m_m_c_pro">mdi-circle</v-icon>
                                  </template>
                                  <span>Local Management Materiality crossed</span>
                                </v-tooltip>
                              </div>
                              <div class="d-flex flex-column">
                                <v-tooltip left content-class="tooltip-left" v-if="v.variance3flag">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on"
                                      style="font-size:7px !important; margin: 1px 1px !important" class="error--text"
                                      id="r_c_m_c_pro">mdi-record-circle-outline</v-icon>
                                  </template>
                                  <span>Regional Control Materiality crossed</span>
                                </v-tooltip>
                                <v-spacer></v-spacer>
                                <v-tooltip left content-class="tooltip-left" v-if="v.variance4flag">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on"
                                      style="font-size:7px !important; margin: 1px 1px !important" class="blue--text"
                                      id="r_m_m_c_pro">mdi-record-circle-outline</v-icon>
                                  </template>
                                  <span>Regional Management Materiality crossed</span>
                                </v-tooltip>
                              </div>
                              <v-spacer></v-spacer>
                            </div>
                          </td>
                          <td :class="`${v['varianceamount' + i] > 0 ? 'success--text font-weight-bold' : v['varianceamount' + i] < 0 ? 'error--text font-weight-bold' : ''} text-right`" :style="`background-color: ${bodycolors[i] || ''} !important`" :key="i + 'b'">
                            {{
                              $nova.formatCurrency(v["varianceamount" + i],"",false,true,1)
                            }}
                          </td>
                          <td :class="`${v['variancepercent' + i] > 0 ? 'success--text font-weight-bold' : v['variancepercent' + i] < 0 ? 'error--text font-weight-bold': ''} text-right`" :style="`background-color: ${bodycolors[i] || ''} !important`" :key="i + 'c'">
                            {{
                              $nova.formatCurrency(v["variancepercent" + i],"",false,true,2)
                            }}
                          </td>
                          <td class="ellipsis-on" :style="`background-color: ${bodycolors[i] || '' } !important; max-width: 150px`" :key="i + 'd'">
                            {{ v["commentary" + i] }}
                          </td>
                        </template>
                        <td>
                          <div class="d-flex justify-center">
                            <v-tooltip bottom content-class="tooltip-bottom"
                              v-if="v.preparer.indexOf(userid) > -1 && metadata.stage === 0">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon small
                                  @click="openCommentryDialog(v, 'add')"><v-icon>mdi-plus-circle</v-icon></v-btn>
                              </template>
                              <span>Add Commentary </span>
                            </v-tooltip>
                            <v-tooltip bottom content-class="tooltip-bottom"
                              v-if="v.approver.indexOf(userid) > -1 && metadata.stage === 0 && v.stage === 1">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon small color="success"
                                  @click="openCommentryDialog(v, 'approve')"><v-icon>mdi-check-circle</v-icon></v-btn>
                              </template>
                              <span>Approve</span>
                            </v-tooltip>
                            <v-tooltip bottom content-class="tooltip-bottom"
                              v-if="v.approver.indexOf(userid) > -1 && metadata.stage === 0 && v.stage === 1">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon small color="error"
                                  @click="openCommentryDialog(v, 'reject')"><v-icon>mdi-close-circle</v-icon></v-btn>
                              </template>
                              <span>Reject</span>
                            </v-tooltip>
                           


                            <NewConversation 
                              class="mt-1"
                              heading="Conversation" 
                              v-if="($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))"
                              width="550" 
                              height="500" 
                              :recurring="false"
                              :recurringText="''" 
                              :closed="metadata.stage !== 0"
                               @markread="v.conversation_read = true"
                              :notify="!v.conversation_read"
                              :getapi="'/v2/fluxmanagement/analysis/getconversation/'+metadata._id+'/c/'+v._id"
                              :sendapi="'/v2/fluxmanagement/analysis/addconversation/'+metadata._id+'/c/'+v._id"
                              :readapi="'/v2/fluxmanagement/analysis/markreadconversation/'+metadata._id+'/c/'+v._id"
                              :mentionapi="'/v2/financialclose/analysis/getusersformdata'" />


                            <!-- <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon small><v-icon>mdi-comment-text-multiple</v-icon></v-btn>
                              </template>
                              <span>Conversations</span>
                            </v-tooltip> -->
                            <lb-activitylognew :config="activitylogconfig" :url="`/v2/fluxmanagement/analysis/getlogs/${v._id}`" />
                            <!-- <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon small><v-icon>mdi-history</v-icon></v-btn>
                              </template>
                              <span>Activity log</span>
                            </v-tooltip> -->
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon small
                                  @click="openCommentryDialog(v, 'show')"><v-icon>mdi-eye</v-icon></v-btn>
                              </template>
                              <span>View Details</span>
                            </v-tooltip>
                          </div>
                        </td>
                        <td class="text-center">
                          <v-tooltip left content-class="tooltip-left" v-if="v.stage === -1">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" color="error">mdi-close-circle</v-icon>
                            </template>
                            <span>Rejected commentary</span>
                          </v-tooltip>
                          <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 0">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" color="info">mdi-timer-sand</v-icon>
                            </template>
                            <span>Commentary pending</span>
                          </v-tooltip>
                          <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 1">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" color="warning">mdi-timer-sand</v-icon>
                            </template>
                            <span>Commentary pending approval</span>
                          </v-tooltip>
                          <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 2">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" color="success">mdi-check-circle</v-icon>
                            </template>
                            <span>Approved commentary</span>
                          </v-tooltip>
                          <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 4">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" color="">mdi-bookmark-off</v-icon>
                            </template>
                            <span>Immaterial variance</span>
                          </v-tooltip>
                          <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 5">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" color="">mdi-cash-remove</v-icon>
                            </template>
                            <span>Nil value accounts</span>
                          </v-tooltip>
                        </td>
                      </template>
                    </template>
                  </tr>
                </v-slide-y-transition>
              </template>
            </v-simple-table>
          </div>
          <div v-else-if="!metadata._id && loading">
            <v-skeleton-loader
              type="table-heading, list-item-two-line, article, article"
            ></v-skeleton-loader>
          </div>
          <div v-else-if="!metadata._id" class="grey--text title text-center pa-8">
            No Data found
          </div>
          <div v-else-if="data.length === 0" class="text-center pa-8">
            <div class="grey--text title">
              No Data Uploaded
            </div>
            <BulkUploadData v-if="!embed && metadata.stage === 0" @reload="refreshData()" :project="(metadata || {})._id" :config="setting" />
          </div>
      </v-card-text>
        <!-- {{ filterTwoArraysOfObjects(data, chartofaccount) }} -->
      </v-card>

      <lb-dailogboxnew v-model="statushelpdialog" heading="Status Notations" width="300" persistent>
        <template v-slot:body>
          <div>
            <div class="my-1">
              <v-icon color="info">mdi-timer-sand</v-icon>
              <span class="ml-2">Commentary pending</span>
            </div>
            <v-divider></v-divider>
            <div class="my-1">
              <v-icon color="warning">mdi-timer-sand</v-icon>
              <span class="ml-2">Commentary pending approval</span>
            </div>
            <v-divider></v-divider>
            <div class="my-1">
              <v-icon color="success">mdi-check-circle</v-icon>
              <span class="ml-2">Approved commentary</span>
            </div>
            <v-divider></v-divider>
            <div class="my-1">
              <v-icon color="error">mdi-close-circle</v-icon>
              <span class="ml-2">Rejected commentary</span>
            </div>
            <v-divider></v-divider>
            <div class="my-1">
              <v-icon color="">mdi-bookmark-off</v-icon>
              <span class="ml-2">Immaterial variance</span>
            </div>
            <v-divider></v-divider>
            <div class="my-1">
              <v-icon color="">mdi-cash-remove</v-icon>
              <span class="ml-2">Nil value accounts</span>
            </div>
          </div>
        </template>
      </lb-dailogboxnew>

      <lb-dailogboxnew v-model="commantorydialog"
        :heading="commantorydialogheading" width="800"
        persistent :loading="commentaryloading">
        <template v-slot:body>
          <div class="mb-2">
            <v-chip :style="`background-color:${$nova.hexToRgbA($vuetify.theme.currentTheme.error, 0.1)}`" small label
              v-if="selectedcommentary.stage === -1">
              <v-icon color="error" class="mr-2">mdi-close-circle</v-icon>
              <span class="error--text font-weight-bold">Rejected</span>
            </v-chip>
            <v-chip :style="`background-color:${$nova.hexToRgbA($vuetify.theme.currentTheme.info, 0.1)}`" small label
              v-else-if="selectedcommentary.stage === 0">
              <v-icon color="info" class="mr-2">mdi-timer-sand</v-icon>
              <span class="info--text font-weight-bold">Commentary pending</span>
            </v-chip>
            <v-chip :style="`background-color:${$nova.hexToRgbA($vuetify.theme.currentTheme.warning, 0.1)}`" small label
              v-if="selectedcommentary.stage === 1">
              <v-icon color="warning" class="mr-2">mdi-timer-sand</v-icon>
              <span class="warning--text font-weight-bold">Commentary pending approval</span>
            </v-chip>
            <v-chip :style="`background-color:${$nova.hexToRgbA($vuetify.theme.currentTheme.success, 0.1)}`" small label
              v-if="selectedcommentary.stage === 2">
              <v-icon color="success" class="mr-2">mdi-check-circle</v-icon>
              <span class="success--text font-weight-bold">Approved commentary</span>
            </v-chip>
            <v-chip small label v-if="selectedcommentary.stage === 4">
              <v-icon color="" class="mr-2">mdi-bookmark-off</v-icon>
              <span class="font-weight-bold">Immaterial variance</span>
            </v-chip>
            <v-chip small label v-if="selectedcommentary.stage === 5">
              <v-icon color="" class="mr-2">mdi-cash-remove</v-icon>
              <span class="font-weight-bold">Nil value</span>
            </v-chip>
          </div>
          <div>
            <div class="">
              <div class="title">{{ selectedcommentary.glcode }}</div>
              <div class="subtitle-1 mb-2">{{ selectedcommentary.name }}</div>
            </div>
            <div class="my-4" v-if="commantorydialogtype === 'approve'">
              <lb-textarea hidedetails v-model="selectedcommentary.approve_reason" label="Approval reason"
              :disabled="metadata.stage !== 0" />
            </div>
            <div class="my-4" v-if="commantorydialogtype === 'reject'">
              <lb-textarea hidedetails v-model="selectedcommentary.reject_reason" label="Rejection reason"
              :disabled="metadata.stage !== 0" />
            </div>
            <div class="mb-6 mt-4" v-if="commantorydialogtype === 'show' && selectedcommentary.stage === -1">
              <div class="font-weight-bold grey--text">Rejection Reason</div>
              <div>{{selectedcommentary.reject_reason}}</div>
            </div>
            <div class="mb-6 mt-4" v-if="commantorydialogtype === 'show' && [2,3].indexOf(selectedcommentary.stage) > -1">
              <div class="font-weight-bold grey--text">Approval Reason</div>
              <div>{{selectedcommentary.approve_reason}}</div>
            </div>
            <v-row>
              <v-col cols="6" v-for="v in setting.comparative" :key="v">
                <div class="pr-2" style="width:50%">
                  <div class="body-1 pl-2" :style="`border-left: 4px solid ${headingcolors[v] || ''} !important`"
                    v-if="v === 1">
                    {{ setting.amount1name }} Vs {{ setting.amount2name }}
                  </div>
                  <div class="body-1 pl-2" :style="`border-left: 4px solid ${headingcolors[v] || ''} !important`"
                    v-if="v === 2">
                    {{ setting.amount1name }} Vs {{ setting.amount3name }}
                  </div>
                  <div class="body-1 pl-2" :style="`border-left: 4px solid ${headingcolors[v] || ''} !important`"
                    v-if="v === 3">
                    {{ setting.amount1name }} Vs {{ setting.amount4name }}
                  </div>
                  <div class="body-1 pl-2" :style="`border-left: 4px solid ${headingcolors[v] || ''} !important`"
                    v-if="v === 4">
                    {{ setting.amount1name }} Vs {{ setting.amount5name }}
                  </div>
                </div>
                <div class="d-flex">
                  <div class="mr-2 py-1">
                    <div class="font-weight-bold grey--text">{{ setting.amount1name }}</div>
                    <div class="subtitle-2">{{ $nova.formatCurrency(selectedcommentary.amount1, '', false, true) }}</div>
                  </div>
                  <div class="mx-2 py-1" v-if="v === 1">
                    <div class="font-weight-bold grey--text">{{ setting.amount2name }}</div>
                    <div class="subtitle-2">{{ $nova.formatCurrency(selectedcommentary.amount2, '', false, true) }}</div>
                  </div>
                  <div class="mx-2 py-1" v-if="v === 2">
                    <div class="font-weight-bold grey--text">{{ setting.amount3name }}</div>
                    <div class="subtitle-2">{{ $nova.formatCurrency(selectedcommentary.amount3, '', false, true) }}</div>
                  </div>
                  <div class="mx-2 py-1" v-if="v === 3">
                    <div class="font-weight-bold grey--text">{{ setting.amount4name }}</div>
                    <div class="subtitle-2">{{ $nova.formatCurrency(selectedcommentary.amount4, '', false, true) }}</div>
                  </div>
                  <div class="mx-2 py-1" v-if="v === 4">
                    <div class="font-weight-bold grey--text">{{ setting.amount5name }}</div>
                    <div class="subtitle-2">{{ $nova.formatCurrency(selectedcommentary.amount5, '', false, true) }}</div>
                  </div>
                  <div class="ml-2 py-1" v-if="v === 1">
                    <div class="font-weight-bold grey--text">Variance</div>
                    <div class="subtitle-2">
                      <span
                        :class="`${(selectedcommentary.varianceamount1 > 0) ? 'success--text font-weight-bold' : ((selectedcommentary.varianceamount1 < 0) ? 'error--text font-weight-bold' : '')}`">{{
                          $nova.formatCurrency(selectedcommentary.varianceamount1,
                            '', false, true) }}</span>
                      <span
                        :class="`ml-2 ${(selectedcommentary.varianceamount1 > 0) ? 'success--text font-weight-bold' : ((selectedcommentary.varianceamount1 < 0) ? 'error--text font-weight-bold' : '')}`">{{
                          $nova.formatCurrency(selectedcommentary.variancepercent1,
                            '', false, true, 2) }} % </span>
                    </div>
                  </div>
                  <div class="ml-2 py-1" v-if="v === 2">
                    <div class="font-weight-bold grey--text">Variance</div>
                    <div class="subtitle-2">
                      <span
                        :class="`${(selectedcommentary.varianceamount2 > 0) ? 'success--text font-weight-bold' : ((selectedcommentary.varianceamount2 < 0) ? 'error--text font-weight-bold' : '')}`">{{
                          $nova.formatCurrency(selectedcommentary.varianceamount2,
                            '', false, true) }}</span>
                      <span
                        :class="`ml-2 ${(selectedcommentary.varianceamount2 > 0) ? 'success--text font-weight-bold' : ((selectedcommentary.varianceamount2 < 0) ? 'error--text font-weight-bold' : '')}`">{{
                          $nova.formatCurrency(selectedcommentary.variancepercent2,
                            '', false, true, 2) }} % </span>
                    </div>
                  </div>
                  <div class="ml-2 py-1" v-if="v === 3">
                    <div class="font-weight-bold grey--text">Variance</div>
                    <div class="subtitle-2">
                      <span
                        :class="`${(selectedcommentary.varianceamount3 > 0) ? 'success--text font-weight-bold' : ((selectedcommentary.varianceamount3 < 0) ? 'error--text font-weight-bold' : '')}`">
                        {{ $nova.formatCurrency(selectedcommentary.varianceamount3, '', false, true) }}</span>
                      <span
                        :class="`ml-2 ${(selectedcommentary.varianceamount3 > 0) ? 'success--text font-weight-bold' : ((selectedcommentary.varianceamount3 < 0) ? 'error--text font-weight-bold' : '')}`">
                        {{ $nova.formatCurrency(selectedcommentary.variancepercent3, '', false, true, 2) }} % </span>
                    </div>
                  </div>
                  <div class="ml-2 py-1" v-if="v === 4">
                    <div class="font-weight-bold grey--text">Variance</div>
                    <div class="subtitle-2">
                      <span
                        :class="`${(selectedcommentary.varianceamount4 > 0) ? 'success--text font-weight-bold' : ((selectedcommentary.varianceamount4 < 0) ? 'error--text font-weight-bold' : '')}`">{{
                          $nova.formatCurrency(selectedcommentary.varianceamount4,
                            '', false, true) }}</span>
                      <span
                        :class="`ml-2 ${(selectedcommentary.varianceamount4 > 0) ? 'success--text font-weight-bold' : ((selectedcommentary.varianceamount4 < 0) ? 'error--text font-weight-bold' : '')}`">{{
                          $nova.formatCurrency(selectedcommentary.variancepercent4,
                            '', false, true, 2) }} % </span>
                    </div>
                  </div>
                </div>
                <div v-if="commantorydialogtype === 'add'">
                  <div class="mt-2" v-if="v === 1">
                    <lb-textarea hidedetails v-model="selectedcommentary.commentary1" label="Commentary"
                      :disabled="metadata.stage !== 0" />
                  </div>
                  <div class="mt-2" v-if="v === 2">
                    <lb-textarea hidedetails v-model="selectedcommentary.commentary2" label="Commentary"
                      :disabled="metadata.stage !== 0" />
                  </div>
                  <div class="mt-2" v-if="v === 3">
                    <lb-textarea hidedetails v-model="selectedcommentary.commentary3" label="Commentary"
                      :disabled="metadata.stage !== 0" />
                  </div>
                  <div class="mt-2" v-if="v === 4">
                    <lb-textarea hidedetails v-model="selectedcommentary.commentary4" label="Commentary"
                      :disabled="metadata.stage !== 0" />
                  </div>
                </div>
                <div v-else>
                  <div class="mt-2" v-if="v === 1">
                    <div class="grey--text font-weight-bold">Commentary</div>
                    <div class="" v-if="selectedcommentary.commentary1">{{ selectedcommentary.commentary1 }}</div>
                    <div class="grey--text" v-else>No Data</div>
                  </div>
                  <div class="mt-2" v-if="v === 2">
                    <div class="grey--text font-weight-bold">Commentary</div>
                    <div class="" v-if="selectedcommentary.commentary2">{{ selectedcommentary.commentary2 }}</div>
                    <div class="grey--text" v-else>No Data</div>
                  </div>
                  <div class="mt-2" v-if="v === 3">
                    <div class="grey--text font-weight-bold">Commentary</div>
                    <div class="" v-if="selectedcommentary.commentary3">{{ selectedcommentary.commentary3 }}</div>
                    <div class="grey--text" v-else>No Data</div>
                  </div>
                  <div class="mt-2" v-if="v === 4">
                    <div class="grey--text font-weight-bold">Commentary</div>
                    <div class="" v-if="selectedcommentary.commentary4">{{ selectedcommentary.commentary4 }}</div>
                    <div class="grey--text" v-else>No Data</div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </template>
        <template v-slot:actions v-if="commantorydialogtype !== 'show'">
          <v-spacer></v-spacer>
          <v-btn v-if="commantorydialogtype === 'add'" @click="updateCommentary()" color="primary" small>Submit</v-btn>
          <v-btn v-else-if="commantorydialogtype === 'approve'" @click="updateCommentary()" color="success" small>Approve</v-btn>
          <v-btn v-else-if="commantorydialogtype === 'reject'" @click="updateCommentary()" color="error" small>Reject</v-btn>
        </template>
      </lb-dailogboxnew>


      <lb-dailogboxnew v-model="bulkactionuserdialog" :heading="'Add/Remove ' + bulkactionuserusertype" width="500"
        :loading="bulkactionuserloading">
        <template v-slot:body>
          <div class="switch-custom-color">
            <div class="d-flex align-center mb-1">
              <div class="display-1 mr-2">{{ multiselectdata.length || 0 }}</div>
              <div class="line-1-2">Item(s)<br />Selected</div>
              <v-spacer></v-spacer>
              <div class="d-flex align-center mb-2">
                <span
                  :class="`mr-3 ${bulkactionuserselected ? 'grey--text text--lighten-1' : ''} font-weight-bold`">Add</span>
                <v-switch inset dense class="mt-0" color="default" @change="bulkActionUser(false)"
                  v-model="bulkactionuserselected" label="" hide-details>
                </v-switch>
                <span
                  :class="`ml-1 ${!bulkactionuserselected ? 'grey--text text--lighten-1' : ''} font-weight-bold`">Remove</span>
              </div>
            </div>
            <div v-if="bulkactionuserselected">
              Below selected user will be removed from all the selected items.
            </div>
            <div v-else>
              Below selected user will be added to all the selected items.
            </div>
            <lb-dropdown hidedetails label="Select User*" v-model="bulkactionuserid" :items="possibleusers"
              itemtext="displayname" itemvalue="_id" class="mt-2" />
          </div>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn color="error" small v-if="bulkactionuserselected" @click="bulkManageUser('remove')">Remove</v-btn>
          <v-btn color="primary" small v-else @click="bulkManageUser('add')">Add</v-btn>
        </template>
      </lb-dailogboxnew>

      <lb-dailogboxnew v-model="bulkcommantorydialog" :heading="'Bulk Submit Commentary'" width="800"
        :loading="commentaryloading">
        <template v-slot:body>
          <div class="switch-custom-color">
            <div class="d-flex align-center mb-1">
              <div class="display-1 mr-2">{{ multiselectdata.length || 0 }}</div>
              <div class="line-1-2">Item(s)<br />Selected</div>
              <v-spacer></v-spacer>
              <!-- <div class="d-flex align-center mb-2">
                <span
                  :class="`mr-3 ${bulkactionuserselected ? 'grey--text text--lighten-1' : ''} font-weight-bold`">Add</span>
                <v-switch inset dense class="mt-0" color="default" @change="bulkActionUser(false)"
                  v-model="bulkactionuserselected" label="" hide-details>
                </v-switch>
                <span
                  :class="`ml-1 ${!bulkactionuserselected ? 'grey--text text--lighten-1' : ''} font-weight-bold`">Remove</span>
              </div> -->
            </div>
            <div class="mb-2">
              The commentary provided here will be updated against all the selected GL(s).
            </div>
            <v-row>
              <v-col cols="6" v-if="setting.comparative > 0" :key="v">
                <div class="body-2 mb-1">{{ setting.amount1name }} Vs {{ setting.amount2name }}</div>
                <lb-textarea hidedetails v-model="selectedcommentary.commentary1" label="Commentary"
                  :disabled="metadata.stage !== 0" />
              </v-col>
              <v-col cols="6" v-if="setting.comparative > 1" :key="v">
                <div class="body-2 mb-1">{{ setting.amount1name }} Vs {{ setting.amount3name }}</div>
                <lb-textarea hidedetails v-model="selectedcommentary.commentary2" label="Commentary"
                  :disabled="metadata.stage !== 0" />
              </v-col>
              <v-col cols="6" v-if="setting.comparative > 2" :key="v">
                <div class="body-2 mb-1">{{ setting.amount1name }} Vs {{ setting.amount4name }}</div>
                <lb-textarea hidedetails v-model="selectedcommentary.commentary3" label="Commentary"
                  :disabled="metadata.stage !== 0" />
              </v-col>
              <v-col cols="6" v-if="setting.comparative > 3" :key="v">
                <div class="body-2 mb-1">{{ setting.amount1name }} Vs {{ setting.amount5name }}</div>
                <lb-textarea hidedetails v-model="selectedcommentary.commentary4" label="Commentary"
                  :disabled="metadata.stage !== 0" />
              </v-col>
            </v-row>
          </div>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" small @click="updateCommentary()">Add</v-btn>
        </template>
      </lb-dailogboxnew>

      <lb-dailogboxnew v-model="customfiltersdialog" heading="Select GL(s) to filter" width="600" persistent>
        <template v-slot:body>
          <div>
            <div class="mb-2">
              <lb-string label="Search" v-model="searchcustom" hidedetails />
            </div>
            <template v-for="(v, k) in chartofaccount">
              <div :key="k"
                v-if="(v.glcode + v.name).replaceAll(' ', '').toLowerCase().indexOf(searchcustom.toLowerCase()) > -1">
                <div class="d-flex align-center pl-2">
                  <v-checkbox class="mt-0" dense label="" v-model="customfilter" multiple :value="v.glcode"
                    hide-details></v-checkbox>
                  <span class="mr-1">{{ v.glcode }}</span>-
                  <span class="ml-1">{{ v.name }}</span>
                </div>
                <v-divider></v-divider>
              </div>
            </template>
          </div>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn small class="ml-2" color="primary" @click="saveCustomFilter()">Save Filter</v-btn>
        </template>
      </lb-dailogboxnew>


    </div>
  </div>
</template>

<script>
let styledxlsx = require('sheetjs-style');
import BulkUploadData from "../views/Components/BulkUploadData.vue";
import NewConversation from "../../../views/common/NewConversation.vue";

export default {
  data() {
    return {
      right:"fluxmanagement_analysis",
      pid: "",
      loading: true,
      setting: {},
      metadata: {},
      summary: {},
      data: [],
      filtereddata: [],
      chartofaccount: [],
      headingcolors: [],
      bodycolors: [],
      collapsed: [],
      userid: "",
      userlist: {},
      commantorydialog: false,
      commantorydialogheading: "",
      viewdialog: false,
      selectedcommentary: {},
      statushelpdialog: false,
      commantorydialogtype: "add",

      ignore0values: true,
      selectallpending: true,
      selectallpendingapproval: true,
      selectallapproved: true,
      selectallrejected: true,
      selectallimmaterial: false,
      multiselectdata: [],
      formdata: {},
      possibleusers: [],
      bulkactionuserdialog: false,
      bulkactionuserid: '',
      bulkactionuserloading: false,
      bulkactionuserusertype: '',
      bulkactionuserselected: false,
      customfilter: [],
      searchcustom: "",
      customfilterstoggle: false,
      customfiltersdialog: false,
      commentaryloading: false,
      bulksubmit: false,
      // bulksubmitfiledatafile: null,
      // bulksubmitfiledata: {},
      // bulksubmitfiledataerror: false,
      // bulksubmitdialog: false,
      // bulksubmitloading: false,
      bulkcommantorydialog: false,
      activitylogconfig: {
        commentaryadd: { icon: "mdi-plus", color: "info", displaytext: "Commentary Added", src: "/img/icons/PenIcon.svg"  },
        bulkcommentaryadd: { icon: "mdi-plus-box-multiple-outline", color: "info", displaytext: "Bulk Commentary Add", src: "/img/icons/FileArrowUp.svg" },
        commentaryapprove: { icon: "mdi-check-circle", color: "success", displaytext: "Commentary Approved", src: "/img/icons/FileArrowUp.svg" },
        commentaryreject: { icon: "mdi-close-circle", color: "success", displaytext: "Commentary Rejected" , src: "/img/icons/CancleCircle.svg"  },
      },
    };
  },
  props: {
    project: {
      type: String,
      default: ""
    },
    embed: {
      type: Boolean,
      default: false
    },
  },
  components: {
    BulkUploadData, NewConversation
  },
  created() {
    this.userid = this.$store.state.user.id;
    this.pid = this.project || this.$route.params.id;
    this.refreshData();
  },
  activated() {
    this.userid = this.$store.state.user.id;
    this.pid = this.project || this.$route.params.id;
    this.refreshData();
  },
  methods: {
    saveCustomFilter: function () {
      localStorage.setItem("fluxmanagement_custom_filter", this.customfilter.join(","));
      if (this.customfilter.length > 0) this.customfilterstoggle = true;
      else this.customfilterstoggle = false;
      localStorage.setItem("fluxmanagement_custom_filter_toggle", this.customfilterstoggle);
      this.customfiltersdialog = false;
    },
    refreshData() {
      this.loading = true;
      this.multiselectdata = [];
      this.customfilter = (localStorage.getItem("fluxmanagement_custom_filter") || "").split(",") || [];
      this.customfilterstoggle = localStorage.getItem("fluxmanagement_custom_filter_toggle") === "true" ? true : false;
      this.axios
        .post("/v2/fluxmanagement/analysis/getdetail/" + this.pid)
        .then((ele) => {
          if (ele.data.status === "success") {
            let d = ele.data.data[0] || {};
            this.data = d.data || [];
            this.metadata = d.metadata || {};
            this.summary = d.summary || {};
            this.setting = d.setting || {};
            this.chartofaccount = d.chartofaccount || [];
            this.headingcolors = this.$nova.getColors(
              (this.setting.comparative || 1) + 1,
              0.28
            );
            this.bodycolors = this.$nova.getColors(
              (this.setting.comparative || 1) + 1,
              0.07
            );
            // console.log(headingcolors, bodycolors);
            return this.axios.post("/v2/fluxmanagement/analysis/getformdata");
          }else throw Error("Error loading project");
        }).then(dt => {
          if (dt.data.status === "success") {
            this.formdata = dt.data.data[0] || {};
            this.userlist = {};
            for (let i = 0; i < (this.formdata.users || []).length; i++) {
              const el = (this.formdata.users || [])[i];
              this.userlist[el._id] = el;
            }
          } else throw Error("Error reading page details");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          // console.log(err);
        })
        .finally(() => {
          this.loading = false;
          this.generateFilteredData();
        });
    },
    generateFilteredData() {
      this.loading = true;
      let coa = {};
      for (const i of this.chartofaccount) {
        coa[i.glcode] = i;
      }
      let categorydata = {};
      let key = 0;
      for (const i of this.data) {
        let ignore = false;
        if (this.customfilterstoggle && this.customfilter.indexOf(i.glcode) === -1) ignore = true;
        if (this.ignore0values) {
          if (i.stage === 5) ignore = true;
        }
        if (!this.selectallpending) {
          if (i.stage === 0) ignore = true;
        }
        if (!this.selectallpendingapproval) {
          if (i.stage === 1) ignore = true;
        }
        if (!this.selectallapproved) {
          if (i.stage === 2) ignore = true;
        }
        if (!this.selectallrejected) {
          if (i.stage === -1) ignore = true;
        }
        if (!this.selectallimmaterial) {
          if (i.stage === 4) ignore = true;
        }
        if (!ignore) {
          i.__key = key;
          i.name = coa[i.glcode].name;
          i.category = coa[i.glcode].category;
          i.subcategory = coa[i.glcode].subcategory;
          let catkey = (i.category || "").toLowerCase().replaceAll(" ", "_");
          i.__category = catkey;
          categorydata[catkey] = categorydata[catkey] || { name: i.category, data: [] };
          categorydata[catkey].data.push(i);
        }
        key++;
      }
      let finaldata = []
      for (const k in categorydata) {
        if (Object.hasOwnProperty.call(categorydata, k)) {
          const el = categorydata[k];
          let hd = { name: el.name, __category: k, type: "group", total: (el.data || []).length, pending: 0, approve: 0, complete: 0, others: 0 };
          let tx = [];
          for (const i of el.data) {
            tx.push(i);
            if (i.stage === 0) hd.pending++;
            else if (i.stage === 1 || i.stage === 2) hd.approve++;
            else if (i.stage === 3) hd.complete++;
            else hd.others++;
            if ((i.preparer || []).indexOf(this.userid) > -1) hd.ispreparer = true;
            if ((i.approver || []).indexOf(this.userid) > -1) hd.isapprover = true;
          }
          if (tx.length > 0) finaldata = [...finaldata, hd, ...tx];
        }
      }
      this.loading = false;
      this.filtereddata = finaldata;
    },
    toggleExpand(v) {
      let vi = this.collapsed.indexOf(v);
      if (vi > -1) this.collapsed.splice(vi, 1);
      else this.collapsed.push(v);
    },
    downloadPPT() {
      this.$nova.downloadFile('', {}, '/v2/fluxmanagement/analysis/downloadppt/'+this.metadata._id);
    },
    openCommentryDialog(v, type) {
      this.commantorydialogtype = type;
      if(type === "add") this.commantorydialogheading = "Commentary Submisstion";
      else if(type === "show") this.commantorydialogheading = "View Commentary";
      else if(type === "approve") this.commantorydialogheading = "Commentary Approval";
      else if(type === "reject") this.commantorydialogheading = "Commentary Rejection";
      this.bulksubmit = false;
      this.selectedcommentary = { ...v };
      this.commantorydialog = true;
    },
    updateCommentary() {
      this.commentaryloading = true;
      let url = "";
      let data = {};
      if (this.commantorydialogtype === "add") {
        data = { data: { commentary1: this.selectedcommentary.commentary1, commentary2: this.selectedcommentary.commentary2, commentary3: this.selectedcommentary.commentary3, commentary4: this.selectedcommentary.commentary4 } };
        if (this.bulksubmit) {
          data.id = this.multiselectdata;
          url = "/v2/fluxmanagement/analysis/addbulkcommentary";
        } else url = "/v2/fluxmanagement/analysis/addcommentary/" + this.selectedcommentary._id;
      } else if (this.commantorydialogtype === "approve") {
        data = { data: { reason: this.selectedcommentary.approve_reason } };
        url = "/v2/fluxmanagement/analysis/approvecommentary/" + this.selectedcommentary._id;
      } else if (this.commantorydialogtype === "reject") {
        data = { data: { reason: this.selectedcommentary.reject_reason } };
        url = "/v2/fluxmanagement/analysis/rejectcommentary/" + this.selectedcommentary._id;
      }
      this.axios.post(url, data).then(dt => {
        if (this.commantorydialogtype === "add") this.responseAddCommentary(dt);
        else if (this.commantorydialogtype === "approve") this.responseApproveCommentary(dt);
        else if (this.commantorydialogtype === "reject") this.responseRejectCommentary(dt);
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.commentaryloading = false;
      })
    },
    responseAddCommentary(dt) {
      if (dt.data.status === "success") {
        this.$store.commit("sbSuccess", "Commentary added");
        this.commantorydialog = false;
        this.bulkcommantorydialog = false;
        if (this.bulksubmit) {
          this.refreshData();
        }
        else {
          this.data[this.selectedcommentary.__key].commentary1 = this.selectedcommentary.commentary1;
          this.data[this.selectedcommentary.__key].commentary2 = this.selectedcommentary.commentary2;
          this.data[this.selectedcommentary.__key].commentary3 = this.selectedcommentary.commentary3;
          this.data[this.selectedcommentary.__key].commentary4 = this.selectedcommentary.commentary4;
          this.data[this.selectedcommentary.__key].stage = 1;
        }
      }
      else throw new Error(dt.data.message || "Error adding commentary");
    },
    responseApproveCommentary(dt) {
      if (dt.data.status === "success") {
        this.$store.commit("sbSuccess", "Commentary approved");
        this.data[this.selectedcommentary.__key].approve_reason = this.selectedcommentary.approve_reason;
        this.data[this.selectedcommentary.__key].stage = 2;
        this.commantorydialog = false;
      }
      else throw new Error(dt.data.message || "Error approving commentary");
    },
    responseRejectCommentary(dt) {
      if (dt.data.status === "success") {
        this.$store.commit("sbSuccess", "Commentary rejected");
        this.data[this.selectedcommentary.__key].reject_reason = this.selectedcommentary.reject_reason;
        this.data[this.selectedcommentary.__key].stage = -1;
        this.commantorydialog = false;
      }
      else throw new Error(dt.data.message || "Error rejecting commentary");
    },
    bulkActionUser(type) {
      let selecteditems = this.multiselectdata;
      let existingusers = new Set();
      let associatedusers = [...(this.metadata.managerlist || []), ...(this.metadata.userlist || [])];
      let possibleids = [];
      if (type) {
        this.bulkactionuserselected = false;
        if (type === 'preparer') this.bulkactionuserusertype = "Preparer";
        else if (type === 'approver') this.bulkactionuserusertype = "Approver";
      }
      for (const i of this.data) {
        if (selecteditems.indexOf(i._id) > -1) {
          if (this.bulkactionuserusertype === 'Preparer') (i.preparer || []).forEach(existingusers.add, existingusers);
          else if (this.bulkactionuserusertype === 'Approver') (i.approver || []).forEach(existingusers.add, existingusers);
        }
      }
      if (!this.bulkactionuserselected) {
        for (const i of (this.formdata.users || [])) {
          if (associatedusers.indexOf(i._id) > -1) this.possibleusers.push(i);
        }
      } else {
        possibleids = Array.from(existingusers);
        // console.log(possibleids);
        this.possibleusers = [];
        for (const i of (this.formdata.users || [])) {
          // console.log(i);
          if (possibleids.indexOf(i._id) > -1) this.possibleusers.push(i);
        }
      }
      this.bulkactionuserdialog = true;
    },
    bulkManageUser(action) {
      let ndt = {}
      this.bulkactionuserloading = true;
      if (action === "remove") ndt = { itemids: this.multiselectdata, action: action, type: (this.bulkactionuserusertype || "").toLowerCase(), userid: this.bulkactionuserid };
      else if (action === "add") ndt = { itemids: this.multiselectdata, action: action, type: (this.bulkactionuserusertype || "").toLowerCase(), userid: this.bulkactionuserid };
      let successflag = false;
      this.axios.post("/v2/fluxmanagement/analysis/bulkmanageuser/" + this.metadata._id, { data: [ndt] }).then(dt => {
        if (dt.data.status === "success") {
          this.bulkactionuserdialog = false;
          if (action === "add") this.$store.commit("sbSuccess", "User Added");
          else if (action === "remove") this.$store.commit("sbSuccess", "User Removed");
          successflag = true;
        } else throw dt.data.message || "Error changing status";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        // console.log(err);
      }).finally(() => {
        this.bulkactionuserloading = false;
        if (successflag) this.refreshData();
      });
    },
    changeProjectStatus(action) {
      let url = "";
      if (action === "freeze") url = "/v2/fluxmanagement/project/freeze/" + this.metadata._id;
      else if (action === "open" || action === "unfreeze") url = "/v2/fluxmanagement/project/open/" + this.metadata._id;
      else if (action === "close") url = "/v2/fluxmanagement/project/close/" + this.metadata._id;
      if (url) {
        this.loading = true;
        this.axios.post(url).then(dt => {
          if (dt.data.status === "success") {
            if (action === "freeze") this.$store.commit("sbSuccess", "Project Freezed");
            else if (action === "unfreeze") this.$store.commit("sbSuccess", "Project Unfreezed");
            else if (action === "open") this.$store.commit("sbSuccess", "Project Opened");
            else if (action === "close") this.$store.commit("sbSuccess", "Project Closed");
          } else throw dt.data.message || "Error changing status";
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          // console.log(err);
        }).finally(() => {
          this.loading = false;
          this.refreshData();
        });
      }
    },
    downloadExcel() {
      // let headcolor = this.headingcolors;
      // let dtcolor = this.bodycolors;
      let headdet = [
      ];
      headdet.push({v: this.setting?.["amount1name"] || ("Amount 1"), s: {font: {bold: true}}});
      for (let i = 0; i < this.setting?.comparative; i++) {
        headdet.push({v: this.setting?.["amount"+(i+2)+"name"] || ("Amount "+i+2), s: {font: {bold: true}}});
        headdet.push({v: "Variance Amt", s: {font: {bold: true}}});
        headdet.push({v: "Variance %", s: {font: {bold: true}}});
        headdet.push({v: "Commentary", s: {font: {bold: true}}});
      }
      let dt = [
        [undefined
        ],
        [
          undefined, 
          {v: this.$store.state.clientlist[0].name || "", s: {font: {bold: true, sz: 20}}}, 
        ],
        [
          undefined, 
          {v: this.metadata.description, s: {font: {bold: true, sz: 14}}}, 
        ],
        [
          undefined, 
          {v: this.$nova.formatDate(this.metadata.from) + " - " + this.$nova.formatDate(this.metadata.from), s: {font: {bold: true, sz: 14}}}, 
        ],
        [
          undefined
        ],
        [
          undefined,
          {v: "GL Code", s: {font: {bold: true}}},
          {v: "Name", s: {font: {bold: true}}},
          {v: "Category", s: {font: {bold: true}}},
          ...headdet,
          {v: "Preparer", s: {font: {bold: true}}},
          {v: "Approver", s: {font: {bold: true}}},
          {v: "Status", s: {font: {bold: true}}},
        ]];
        for (const v of this.data) {
          let tdt = [];
          tdt.push(undefined);
          tdt.push(v.glcode);
          tdt.push(v.name);
          tdt.push(v.category);
          tdt.push({v: v.amount1, z: '#,##', t: 'n'});
          for (let i = 0; i < this.setting?.comparative; i++) {
            tdt.push({v: v["amount"+(i+1)] || 0, z: '#,##', t: 'n'});
            tdt.push({v: v["varianceamount"+(i+1)] || 0, z: '#,##', t: 'n'});
            tdt.push({v: v["variancepercent"+(i+1)] || 0, z: '#,##0.00', t: 'n'});
            tdt.push({v: v["commentary"+(i+1)] || ""});
          }
          tdt.push(this.getUserList(v.preparer));
          tdt.push(this.getUserList(v.approver));
          tdt.push(this.getStageText(v.stage));
          dt.push(tdt);
        }
        // console.log(dt);

        // Extract Data (create a workbook object from the table)
        let workbook = styledxlsx.utils.book_new();
        let worksheet = styledxlsx.utils.aoa_to_sheet(dt);
        styledxlsx.utils.book_append_sheet(workbook, worksheet, "Analysis");
        styledxlsx.writeFile(workbook, this.metadata.description + " - Analysis.xlsx");

        // console.log(xlsx);
    },
    getUserList(x){
      let rdt = [];
      for (const i of x) {
        rdt.push(this.userlist[i]?.displayname || "")
      }
      return rdt.join(", ")
    },
    getStageText(x){
      if(x === 0) return "Pending Submission";
      else if(x === 1) return "Processing";
      else if(x === 2) return "Pending Approval";
      else if(x === 3) return "Closed";
      else return "Unknown";
    },
  },
  watch: {
    "customfilterstoggle": function (v) {
      localStorage.setItem("fluxmanagement_custom_filter_toggle", v)
      this.generateFilteredData();
    },
  }
};
</script>
